import { PicCenterOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { PAGE_URLS } from 'common/constants';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const FisheryBoatList = () => {
    const { data: boatList } = useSelector((s) => s.fisheryBoatList);

    return (
        <Menu mode="horizontal" selectable={false} className="header-boat-menu">
            {boatList.map((boat, index) => (
                <Menu.Item key={`${boat.id} - ${index}`} disabled={!boat.hasData}>
                    <Link rel="noopener noreferrer" to={`${PAGE_URLS.BOAT_DETAIL}/${boat.id}`}>
                        <PicCenterOutlined />
                        <span>{boat.name}</span>
                    </Link>
                </Menu.Item>
            ))}
        </Menu>
    );
};

export default FisheryBoatList;
