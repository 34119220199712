import {
    AreaChartOutlined,
    FileDoneOutlined,
    HomeOutlined,
    PicCenterOutlined,
    UsergroupAddOutlined
} from '@ant-design/icons';
import { useIsAuthenticated } from '@azure/msal-react';
import { Layout as LibLayout, Menu } from 'antd';
import useAppAbility from 'casl/can';
import { PAGE_URLS, ROLES } from 'common/constants';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import i18n from 'translation/i18n';
import './Sidebar.scss';

const Sidebar = () => {
    const { t } = useTranslation();
    const { Sider } = LibLayout;
    const { can } = useAppAbility();
    const [collapsed] = useState(false);
    const isAuthenticated = useIsAuthenticated();

    const location = useLocation();

    const menu = useMemo(() => {
        if (!isAuthenticated) return [];
        return [
            {
                icon: <HomeOutlined />,
                title: <span>{t('layout.sidebar.dashboard')}</span>,
                path: PAGE_URLS.DASHBOARD,
                visible: can('manage', ROLES.USER, ['all'])
            },
            {
                icon: <FileDoneOutlined />,
                title: <span>{t('layout.sidebar.daily')}</span>,
                path: PAGE_URLS.DAILY,
                visible: can('manage', ROLES.USER, ['all'])
            },
            {
                icon: <AreaChartOutlined />,
                title: <span>{t('layout.sidebar.catch')}</span>,
                path: PAGE_URLS.MORTALITY,
                visible: can('manage', ROLES.USER, ['all'])
            },
            {
                icon: <PicCenterOutlined />,
                title: <span>{t('layout.sidebar.fisheryBoat')}</span>,
                path: PAGE_URLS.FISHERY_BOAT,
                visible: can('manage', ROLES.CAPTAIN, ['all'])
            },
            {
                icon: <UsergroupAddOutlined />,
                title: <span>{t('layout.sidebar.user')}</span>,
                path: PAGE_URLS.USER,
                visible: can('manage', ROLES.ADMINISTRATOR, ['all'])
            }
        ].filter((ability) => {
            return ability.visible;
        });
    }, [isAuthenticated, i18n.language]);

    const menuItems = menu.map((menuItem) => ({
        label: (
            <NavLink to={menuItem.path}>
                <span>{menuItem.icon}</span>
                <span className="title">{menuItem.title}</span>
            </NavLink>
        ),
        key: menuItem.path,
        className: 'item'
    }));

    return (
        <Sider trigger={null} className="sidebar" collapsible collapsed={collapsed} width="250">
            <Link to="/">
                <div className={`brand-name ${collapsed && 'collapsed'}`}>
                    <div className="logo" />
                </div>
            </Link>
            <Menu
                theme="light"
                className="menu"
                selectedKeys={[location.pathname]}
                defaultSelectedKeys={['/']}
                mode="inline"
                items={menuItems}
            />
        </Sider>
    );
};

export default Sidebar;
