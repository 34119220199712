import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export var reactPlugin = new ReactPlugin();

var appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
    }
});
appInsights.loadAppInsights();
