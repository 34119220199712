import { useMsal } from '@azure/msal-react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import { Button, Result } from 'antd';
import { CLASS_NAMES } from 'common/constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SessionExpired = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect();
    };

    return (
        <Result
            status="403"
            title={t('sessionExpired.title')}
            subTitle={t('sessionExpired.subTitle')}
            extra={
                <div className="flex justify-center gap-x-2">
                    <Link to="/">
                        <Button className={CLASS_NAMES.BLUE_BUTTON}>{t('button.backHome')}</Button>
                    </Link>
                    <Button className={CLASS_NAMES.GRAY_BUTTON} onClick={handleLogout}>
                        {t('button.logout')}
                    </Button>
                </div>
            }
        />
    );
};

export default withAITracking(reactPlugin, SessionExpired);
