import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import { FISH_TYPES_CARD } from 'common/constants';
import { NumberUtils } from 'common/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { getFishCatchCard } from 'redux/thunks';
import './FishCatchCard.scss';

const FishCatchCard = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { fisheryBoatId } = useParams();

    const filters = useSelector((s) => s.filters.data);
    const { data } = useSelector((s) => s.fishCatchCard);

    useEffect(() => {
        dispatch(
            getFishCatchCard({
                fisheryBoatIds: [fisheryBoatId],
                fromDate: filters.timePeriod.fromDate
            })
        );
    }, [fisheryBoatId, filters.timePeriod.fromDate]);

    return (
        <div className="fish-catch-card">
            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <Card className="h-full catch-count">
                        <div className="w-full h-full flex justify-center xs:flex-col lg:flex-row lg:space-x-16 xl:flex-col xl:space-x-0 xxl:flex-row xxl:space-x-16">
                            <div className="title flex justify-center items-center">
                                <span className="text-lg text-gray-600 font-semibold">
                                    {t('catchCard.catchCount')}
                                </span>
                            </div>

                            <div className="h-full flex justify-center align-center">
                                <table>
                                    <tbody>
                                        {Array.from(
                                            [...FISH_TYPES_CARD, ...(data.mortalityCount || [])]
                                                .reduce((m, o) => m.set(o.fishName, o), new Map())
                                                .values()
                                        ).map((item) => (
                                            <tr key={item.fishName}>
                                                <td className="text-right">
                                                    <span className="text-gray-500 text-base py-1 mr-1">
                                                        {t(`fishCatch.${item.fishName}`)}:
                                                    </span>
                                                </td>
                                                <td className="text-left">
                                                    <span className="font-semibold text-xl">
                                                        {item.count}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Card>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <Card className="catch-trend h-full">
                        <div className="w-full flex justify-center items-center xs:flex-col lg:flex-row lg:space-x-16 xl:flex-col xl:space-x-0 xxl:flex-row xxl:space-x-16">
                            <div className="title flex justify-center items-center">
                                <span className="text-lg text-gray-600 font-semibold">
                                    {t('catchCard.catchTrend')}
                                </span>
                            </div>

                            <div className="data flex xs:space-x-4 sm:space-x-16">
                                <div className="flex flex-col items-center">
                                    <span className="text-gray-500 text-base mr-1">
                                        {t('fishCatch.rate')}
                                    </span>
                                    <span
                                        className={`text-xl font-semibold text-gray-500 ${
                                            data.rate > 0 ? 'text-red-600' : 'text-green-700'
                                        }`}
                                    >
                                        {data.rate > 0 ? (
                                            <ArrowUpOutlined />
                                        ) : (
                                            <ArrowDownOutlined />
                                        )}
                                        {Math.abs(NumberUtils.roundTwoDigits(data.rate))}%
                                    </span>
                                </div>

                                <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="text-right">
                                                    <span className="text-gray-500 text-base py-1 mr-1">
                                                        {t('catchCard.lastPeriod')}:
                                                    </span>
                                                </td>
                                                <td className="text-left">
                                                    <span className="font-semibold text-xl">
                                                        {data.previousCount}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-right">
                                                    <span className="text-gray-500 text-base py-1 mr-1">
                                                        {t('catchCard.thisPeriod')}:
                                                    </span>
                                                </td>
                                                <td className="text-left">
                                                    <span className="font-semibold text-xl">
                                                        {data.currentCount}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default FishCatchCard;
