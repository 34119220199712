import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import { FishCatch } from 'components';
import FisheryBoatInfo from './FisheryBoatInfo';

const FisheryBoatDetail = () => {
    return (
        <>
            <FisheryBoatInfo />
            <FishCatch />
        </>
    );
};

export default withAITracking(reactPlugin, FisheryBoatDetail);
