import { DateTimeUtils } from 'common/utils';
import { FORMAT_TYPES } from './format-types';

// table columns for report table
export const COLUMNS_FOR_PER_COUNT_REPORT = [
    {
        title: 'table.fisheryBoat',
        dataIndex: 'fisheryBoat',
        className: 'table-report-boat'
    },
    {
        title: 'table.startTime',
        dataIndex: 'startTime',
        sorter: {
            compare: (a, b) => new Date(a.startTime) - new Date(b.startTime),
            multiple: 2
        },
        render: (startTime) =>
            DateTimeUtils.formatDateTime(startTime, FORMAT_TYPES.NORWEGIAN_DATE_TIME),
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-start-time'
    },
    {
        title: 'table.stopTime',
        dataIndex: 'stopTime',
        className: 'table-report-stop-time',
        render: (stopTime) =>
            DateTimeUtils.formatDateTime(stopTime, FORMAT_TYPES.NORWEGIAN_DATE_TIME),
        sorter: {
            compare: (a, b) => new Date(a.stopTime) - new Date(b.stopTime),
            multiple: 1
        },
        sortDirections: ['ascend', 'descend']
    },
    {
        title: 'fishCatch.torsk',
        dataIndex: 'torsk',
        sorter: {
            compare: (a, b) => a.torsk - b.torsk,
            multiple: 3
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-torsk'
    },
    {
        title: 'fishCatch.hyse',
        dataIndex: 'hyse',
        sorter: {
            compare: (a, b) => a.hyse - b.hyse,
            multiple: 4
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-hyse'
    },
    {
        title: 'fishCatch.sei',
        dataIndex: 'sei',
        sorter: {
            compare: (a, b) => a.sei - b.sei,
            multiple: 5
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-sei'
    }
];

export const COLUMNS_FOR_PER_DAY_REPORT = [
    {
        title: 'table.date',
        dataIndex: 'stopTime',
        render: (startTime) => DateTimeUtils.formatDateTime(startTime, FORMAT_TYPES.NORWEGIAN_DATE),
        sorter: {
            compare: (a, b) => new Date(a.stopTime) - new Date(b.stopTime),
            multiple: 1
        },
        sortDirections: ['ascend', 'descend']
    },
    {
        title: 'table.fisheryBoat',
        dataIndex: 'fisheryBoat'
    },
    {
        title: 'fishCatch.torsk',
        dataIndex: 'torsk',
        sorter: {
            compare: (a, b) => a.torsk - b.torsk,
            multiple: 2
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-torsk'
    },
    {
        title: 'fishCatch.hyse',
        dataIndex: 'hyse',
        sorter: {
            compare: (a, b) => a.hyse - b.hyse,
            multiple: 3
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-hyse'
    },
    {
        title: 'fishCatch.sei',
        dataIndex: 'sei',
        sorter: {
            compare: (a, b) => a.sei - b.sei,
            multiple: 4
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-sei'
    }
];
