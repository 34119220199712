import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_ENDPOINTS } from 'common/constants';
import { API } from 'services';

export const getFisheryBoatListOnNavbar = createAsyncThunk(
    'fisheryBoats/getFisheryBoatListOnNavbar',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(API_ENDPOINTS.FISHERY_BOAT_LIST, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFisheryBoatDetail = createAsyncThunk(
    'fisheryBoats/getFisheryBoatDetail',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${API_ENDPOINTS.FISHERY_BOAT}/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFisheryBoats = createAsyncThunk(
    'fisheryBoats/getFisheryBoats',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(API_ENDPOINTS.FISHERY_BOAT, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const createFisheryBoat = createAsyncThunk(
    'fisheryBoats/createFisheryBoat',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.post(API_ENDPOINTS.FISHERY_BOAT, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateFisheryBoat = createAsyncThunk(
    'fisheryBoats/updateFisheryBoat',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${API_ENDPOINTS.FISHERY_BOAT}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteFisheryBoat = createAsyncThunk(
    'fisheryBoats/deleteFisheryBoat',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.delete(`${API_ENDPOINTS.FISHERY_BOAT}/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const restoreFisheryBoat = createAsyncThunk(
    'fisheryBoats/restoreFisheryBoat',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${API_ENDPOINTS.FISHERY_BOAT}/restore`, { id });
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
