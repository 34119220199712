import GeneralInfo from 'components/GeneralInfo';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { getFisheryBoatDetail } from 'redux/thunks';

const FisheryBoatInfo = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { fisheryBoatId } = useParams();

    const { data: boat, loading } = useSelector((s) => s.fisheryBoatDetail);

    useEffect(() => {
        dispatch(getFisheryBoatDetail(fisheryBoatId));
    }, [fisheryBoatId]);

    const boatInfo = [
        {
            key: t('fisheryBoatDetail.companyName'),
            value: boat?.companyOwnerName
        },
        {
            key: t('fisheryBoatDetail.boatName'),
            value: boat?.name
        },
        {
            key: t('fisheryBoatDetail.externalBoatId'),
            value: boat?.externalBoatId
        },
        {
            key: t('fisheryBoatDetail.description'),
            value: boat?.description
        }
    ];

    return (
        <GeneralInfo
            loading={loading}
            title={t('fisheryBoatDetail.title')}
            infoList={boatInfo}
            logoUrl={boat?.companyOwnerLogoUrl}
            fisheryBoatId={fisheryBoatId}
        />
    );
};

export default FisheryBoatInfo;
