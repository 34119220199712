export const FISH_TYPES = [
    {
        id: 'torsk',
        text: 'fishCatch.torsk'
    },
    {
        id: 'hyse',
        text: 'fishCatch.hyse'
    },
    {
        id: 'sei',
        text: 'fishCatch.sei'
    }
];

export const FISH_TYPES_CARD = [
    {
        fishName: 'torsk',
        count: 0
    },
    {
        fishName: 'hyse',
        count: 0
    },
    {
        fishName: 'sei',
        count: 0
    }
];
