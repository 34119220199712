import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_ENDPOINTS } from 'common/constants';
import { API } from 'services';

export const getCurrentProfile = createAsyncThunk(
    'me/getProfile',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(API_ENDPOINTS.PROFILE, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateCurrentProfile = createAsyncThunk(
    'me/updateProfile',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.put(API_ENDPOINTS.PROFILE, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
