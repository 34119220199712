import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Avatar, Menu, Popover } from 'antd';
import defaultAvatar from 'assets/Default_Avatar.png';
import { PAGE_URLS } from 'common/constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const DropdownProfile = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();

    const { data: profile } = useSelector((s) => s.profile);

    const handleLogout = () => {
        instance.logoutRedirect();
    };

    return (
        <Popover
            className="profile-dropdown"
            getPopupContainer={(trigger) => trigger.parentElement}
            content={
                <Menu mode="inline" className="border-none" selectable={false}>
                    <Menu.Item key="profile" className="hover:bg-gray-300">
                        <NavLink to={PAGE_URLS.PROFILE} className="flex items-center">
                            <UserOutlined />
                            <span>{t('layout.header.viewProfile')}</span>
                        </NavLink>
                    </Menu.Item>

                    <Menu.Divider />

                    <Menu.Item key="logout" className="hover:bg-gray-300">
                        <div className="flex items-center" onClick={handleLogout}>
                            <LogoutOutlined />
                            <span>{t('layout.header.logout')}</span>
                        </div>
                    </Menu.Item>
                </Menu>
            }
            placement="bottomRight"
        >
            <span className="user-name cursor-pointer hidden mr-2 xl:!inline">
                {t('layout.header.greeting')} {profile?.name || '...'}
            </span>
            <Avatar className="cursor-pointer w-8" src={profile?.avatar || defaultAvatar} />
        </Popover>
    );
};

export default DropdownProfile;
