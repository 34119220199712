import { ALERT_MESSAGES } from 'common/constants';
import Swal from 'sweetalert2';
import i18n from 'translation/i18n';

export const flattenRoutes = (routes) =>
    routes
        .map((route) => [route.children ? flattenRoutes(route.children) : [], route])
        .flat(Infinity)
        .filter(({ name }) => name);

export const alertSuccessMessage = (message) => {
    Swal.fire({
        icon: 'success',
        title: i18n.t(message),
        timer: 5000
    });
};

export const alertErrorMessage = (message) => {
    if (typeof message === 'object') {
        message = message[0]?.errorMessage || ALERT_MESSAGES.ERROR_OCCURRED;
    }

    if (!message) {
        message = ALERT_MESSAGES.INTERNAL_SERVER;
    }

    Swal.fire({
        icon: 'error',
        title: i18n.t(message)
    });
};
