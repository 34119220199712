import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';
import useAppAbility from 'casl/can';
import { PAGE_URLS } from 'common/constants';

const ProtectedRoute = ({ children, isPrivate = false, role }) => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress } = useMsal();
    const { can } = useAppAbility();

    if (inProgress === InteractionStatus.Logout) return;

    if (isPrivate && !isAuthenticated && inProgress === InteractionStatus.None) {
        instance.loginRedirect(loginRequest);
    }

    if (isPrivate && !can('manage', role, ['all'])) {
        window.location.href = PAGE_URLS.FORBIDDEN;
        return;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
