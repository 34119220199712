import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_ENDPOINTS } from 'common/constants';
import { API } from 'services';

export const getFisheryBoatsSelection = createAsyncThunk(
    'selections/fisheryBoatsSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(API_ENDPOINTS.FISHERY_BOATS_SELECTION, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
