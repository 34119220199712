import { Spin } from 'antd';
import { FORMAT_TYPES, PAGE_URLS } from 'common/constants';
import { DateTimeUtils } from 'common/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { getFishCatchDailyCounting, updateFilters } from 'redux/thunks';
import './DailyCountButton.scss';

const DailyCountButton = ({ fisheryBoatId }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const filters = useSelector((s) => s.filters.data);
    const { data: count, loading } = useSelector((s) => s.fishCatchDailyCounting);

    useEffect(() => {
        const filters = {
            fisheryBoatIds: fisheryBoatId ? [fisheryBoatId] : [],
            fromDate: DateTimeUtils.formatDateTime(new Date(), FORMAT_TYPES.API_DATE)
        };
        dispatch(getFishCatchDailyCounting(filters));
    }, [fisheryBoatId]);

    const handleDailyCountClick = async () => {
        await dispatch(
            updateFilters({
                ...filters,
                fisheryBoatIds: fisheryBoatId ? [fisheryBoatId] : [],
                dailyDate: DateTimeUtils.formatDateTime(new Date(), FORMAT_TYPES.API_DATE)
            })
        ).unwrap();

        navigate(PAGE_URLS.DAILY);
    };

    return (
        <div
            className="catch-count-button text-center transition-all"
            onClick={handleDailyCountClick}
        >
            <div className="text-white text-3xl font-semibold mb-3">
                {loading ? <Spin /> : count}
            </div>
            <div className="text-md text-slate-300">{t('dailyCountButton.viewTodayReport')}</div>
        </div>
    );
};

export default DailyCountButton;
