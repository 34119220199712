export const PAGE_URLS = {
    FORBIDDEN: '/forbidden',
    SESSION_EXPIRED: '/session-expired',
    UNAUTHORIZED: '/unauthorized',
    DASHBOARD: '/',
    MORTALITY: '/mortality',
    PROFILE: '/profile',
    BOAT_DETAIL: '/boat-detail',
    FISHERY_BOAT: '/fishery-boat',
    DAILY: '/daily',
    USER: '/user'
};
