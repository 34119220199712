import { createAsyncThunk } from '@reduxjs/toolkit';

export const updateFilters = createAsyncThunk(
    'filter/updateFilters',
    async (data, { rejectWithValue }) => {
        try {
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
