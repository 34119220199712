import { Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './FBSelection.scss';

const FBSelection = ({
    showSearch = true,
    disabled = false,
    loading = false,
    allowClear = false,
    listSelectItem = [],
    className,
    placeholder,
    defaultValue,
    value,
    mode,
    dataTestId,
    onChange
}) => {
    const { t } = useTranslation();

    const handleChange = (value, object) => {
        if (onChange) {
            onChange(value, object);
        }
    };

    return (
        <Select
            mode={mode}
            placeholder={placeholder || t('filterBar.selectionPlaceholder')}
            onChange={handleChange}
            defaultValue={defaultValue}
            value={value}
            className={className}
            filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
            }
            allowClear={allowClear}
            showSearch={showSearch}
            showArrow
            disabled={disabled}
            loading={loading}
        >
            {listSelectItem.map((item) => (
                <Select.Option
                    key={item?.id}
                    value={item?.id}
                    from={item.from}
                    to={item.to}
                    data-testid={dataTestId}
                    disabled={item.disabled}
                >
                    {item?.text}
                </Select.Option>
            ))}
        </Select>
    );
};

FBSelection.propTypes = {
    showSearch: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    allowClear: PropTypes.bool,
    listSelectItem: PropTypes.array,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    mode: PropTypes.string,
    dataTestId: PropTypes.string,
    onChange: PropTypes.func
};

export default FBSelection;
