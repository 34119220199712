import FilterBar from './FilterBar';
import FishMortality from './FishMortality';
import ReportTable from './ReportTable';

const Mortality = () => {
    return (
        <div className="mortality-page">
            <FilterBar />

            <FishMortality />

            <ReportTable />
        </div>
    );
};

export default Mortality;
