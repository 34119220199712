import { DownloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { FORMAT_TYPES } from 'common/constants';
import { DateTimeUtils } from 'common/utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from 'services';

const FBDropdownDownload = ({ options = [], fileName, exportApi, method = 'GET' }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const downloadOptions = (
        <Menu
            items={options.map((option, index) => {
                return {
                    label: option.label,
                    key: index,
                    icon: <DownloadOutlined />,
                    className: option.className,
                    onClick: () => exportExcelFile(exportApi, option.params, fileName)
                };
            })}
        />
    );

    const createDownloadLink = (res, fileName) => {
        const outputFilename = `${fileName}-${DateTimeUtils.formatDateTime(
            Date.now(),
            FORMAT_TYPES.FILE_EXPORT_DATE
        )}.xlsx`;

        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
    };

    const exportExcelFile = async (api, params, fileName) => {
        setLoading(true);

        switch (method) {
            case 'GET':
                await API.export(api, params)
                    .then((res) => createDownloadLink(res, fileName))
                    .catch((error) => {
                        throw Error(error);
                    });
                break;
            case 'POST':
                await API.postExport(api, params)
                    .then((res) => createDownloadLink(res, fileName))
                    .catch((error) => {
                        throw Error(error);
                    });
                break;
            default:
        }

        setLoading(false);
    };

    return (
        <Dropdown placement="bottomRight" arrow overlay={downloadOptions}>
            <div className="h-full flex items-end">
                <Button
                    loading={loading}
                    type="default"
                    shape="round"
                    className="btn-download report-download-button"
                    icon={<DownloadOutlined />}
                >
                    <span>{t('download.title')}</span>
                </Button>
            </div>
        </Dropdown>
    );
};

FBDropdownDownload.propTypes = {
    options: PropTypes.array,
    fileName: PropTypes.string,
    exportApi: PropTypes.string,
    method: PropTypes.string
};

export default FBDropdownDownload;
