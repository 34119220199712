import { createSlice } from '@reduxjs/toolkit';
import {
    FORMAT_TYPES,
    GRAPH_TYPES,
    PREVIOUS_DAYS_DEFAULT,
    REPORT_TYPES,
    TIME_PERIOD_DEFAULT
} from 'common/constants';
import { DateTimeUtils } from 'common/utils';
import { updateFilters } from 'redux/thunks';

const initialState = {
    data: {
        dailyDate: DateTimeUtils.formatDateTime(new Date(), FORMAT_TYPES.API_DATE),
        fishNames: [],
        graphType: GRAPH_TYPES.PER_DAY,
        reportType: REPORT_TYPES.PER_COUNT,
        fromDead: null,
        toDead: null,
        fromDate: DateTimeUtils.formatDateTime(
            DateTimeUtils.getPreviousDate(PREVIOUS_DAYS_DEFAULT),
            FORMAT_TYPES.API_DATE
        ),
        toDate: DateTimeUtils.formatDateTime(new Date(), FORMAT_TYPES.API_DATE),
        timePeriod: {
            value: TIME_PERIOD_DEFAULT.id,
            fromDate: DateTimeUtils.formatDateTime(
                DateTimeUtils.getPreviousDate(PREVIOUS_DAYS_DEFAULT),
                FORMAT_TYPES.API_DATE
            )
        },
        fisheryBoatIds: []
    },
    loading: false
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {},
    extraReducers: {
        [updateFilters.pending]: (state) => {
            state.loading = true;
        },
        [updateFilters.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [updateFilters.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
