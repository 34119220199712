import { Col, Row } from 'antd';
import { FBDropdownDownload, FBSelection, FBTable } from 'common/components';
import {
    API_ENDPOINTS,
    CLASS_NAMES,
    COLUMNS_FOR_PER_COUNT_REPORT,
    COLUMNS_FOR_PER_DAY_REPORT,
    COUNT_RANGES_SELECTION,
    EXPORT_FILE_NAMES,
    EXPORT_FILE_TYPES,
    REPORT_TYPES
} from 'common/constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getFishCatchCounting, updateFilters } from 'redux/thunks';

const ReportTable = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const filters = useSelector((s) => s.filters.data);
    const { data, loading } = useSelector((s) => s.fishCatchCounting);

    const [columns, setColumns] = useState(COLUMNS_FOR_PER_COUNT_REPORT);
    const [downloadOptions, setDownloadOptions] = useState([]);

    useEffect(() => {
        const currentfilters = {
            fisheryBoatIds: filters.fisheryBoatIds,
            fishNames: filters.fishNames,
            fromDate: filters.fromDate,
            toDate: filters.toDate,
            reportType: filters.reportType,
            fromDead: filters.fromDead,
            toDead: filters.toDead
        };
        setDownloadOptions([
            {
                label: t('reportTable.download.byCurrentFilter'),
                params: { ...currentfilters, exportType: EXPORT_FILE_TYPES.CURRENT_FILTER },
                className: 'download-current-filter-data-button'
            },
            {
                label: t('reportTable.download.allData'),
                params: { ...currentfilters, exportType: EXPORT_FILE_TYPES.ALL },
                className: 'download-all-data-button'
            }
        ]);

        dispatch(getFishCatchCounting(currentfilters));
    }, [
        filters.fisheryBoatIds,
        filters.fishNames,
        filters.fromDate,
        filters.toDate,
        filters.reportType,
        filters.fromDead,
        filters.toDead
    ]);

    const handleReportTypeChange = (newType) => {
        if (newType === REPORT_TYPES.PER_COUNT) {
            setColumns(COLUMNS_FOR_PER_COUNT_REPORT);
        } else if (newType === REPORT_TYPES.PER_DAY) {
            setColumns(COLUMNS_FOR_PER_DAY_REPORT);
        }

        dispatch(
            updateFilters({
                ...filters,
                reportType: newType
            })
        );
    };

    const handleDeadChange = (value, object) => {
        dispatch(
            updateFilters({
                ...filters,
                fromDead: object ? object.from : null,
                toDead: object ? object.to : null
            })
        );
    };

    const convertColumns = (columns) => {
        const reports = convertReportData(data || []);

        if (reports?.length) {
            return columns.filter((col) => reports[0].hasOwnProperty(col.dataIndex));
        }

        return columns;
    };

    const convertReportData = (reports) => {
        return reports.map((item, index) => {
            let obj = Object.assign(
                {},
                item,
                item.fishes.reduce(
                    (accumulator, currentValue) => ({
                        key: index,
                        ...accumulator,
                        [currentValue.fishId]: currentValue.dead
                    }),
                    {}
                )
            );

            delete obj.fishes;

            return obj;
        });
    };

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('reportTable.title')}</h2>
            <Row className="inline flex justify-between w-full mb-2">
                <Col
                    className="inline mr-4 flex xs:justify-center xl:justify-start py-1"
                    xs={24}
                    xl={8}
                >
                    <button
                        className={
                            filters.reportType === REPORT_TYPES.PER_COUNT
                                ? CLASS_NAMES.SELECTED_BLUE_BUTTON
                                : CLASS_NAMES.UNSELECTED_BLUE_BUTTON
                        }
                        onClick={() => handleReportTypeChange(REPORT_TYPES.PER_COUNT)}
                    >
                        {t('reportTable.perCount')}
                    </button>
                    &nbsp;
                    <button
                        className={
                            filters?.reportType === REPORT_TYPES.PER_DAY
                                ? CLASS_NAMES.SELECTED_BLUE_BUTTON
                                : CLASS_NAMES.UNSELECTED_BLUE_BUTTON
                        }
                        onClick={() => handleReportTypeChange(REPORT_TYPES.PER_DAY)}
                    >
                        {t('reportTable.perDay')}
                    </button>
                </Col>

                <Col className="flex xs:justify-between xl:justify-end py-1" xs={24} xl={15}>
                    <div className="xs:flex-col sm:flex-row mortality-count-filter">
                        <span className="font-semibold title text-base text-gray-700">
                            {t('reportTable.catchCount.title')}{' '}
                        </span>
                        <FBSelection
                            placeholder={t('reportTable.catchCount.placeholder')}
                            className="w-52 select xl:mr-10"
                            listSelectItem={COUNT_RANGES_SELECTION.map((range) => ({
                                ...range,
                                text: t(range.text)
                            }))}
                            onChange={handleDeadChange}
                            allowClear={true}
                        />
                    </div>
                    <FBDropdownDownload
                        options={downloadOptions}
                        exportApi={API_ENDPOINTS.EXPORT_FISH_CATCH_COUNTING}
                        fileName={EXPORT_FILE_NAMES.DEAD_FISH_COUNTING_REPORT}
                    />
                </Col>
            </Row>

            <FBTable
                columns={convertColumns(columns)}
                data={convertReportData(data || [])}
                className="report-table"
                loading={loading}
            />
        </div>
    );
};

export default ReportTable;
