import { Col, Row } from 'antd';
import { FBDate, FBMultipleSelection } from 'common/components';
import { CLASS_NAMES, FISH_TYPES, FORMAT_TYPES, Title } from 'common/constants';
import { DateTimeUtils } from 'common/utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getDailyReport, getFisheryBoatsSelection, updateFilters } from 'redux/thunks';
import './FilterBar.scss';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const fisheryBoatsSelection = useSelector((s) => s.fisheryBoatsSelection.data);
    const initialFilters = useSelector((s) => s.filters.data);

    const [filters, setFilters] = useState({
        fisheryBoatIds: initialFilters.fisheryBoatIds,
        fishNames: initialFilters.fishNames,
        fromDate: initialFilters.dailyDate,
        toDate: initialFilters.dailyDate
    });

    useEffect(() => {
        dispatch(getFisheryBoatsSelection());
        dispatch(getDailyReport(filters));
    }, []);

    const handleFisheryBoatChange = (fisheryBoatIds) => {
        setFilters({
            ...filters,
            fisheryBoatIds: fisheryBoatIds
        });
    };

    const handleFishNamesChange = (fishNames) => {
        setFilters({
            ...filters,
            fishNames: fishNames
        });
    };

    const handleDateChange = (date) => {
        setFilters({
            ...filters,
            fromDate: date.format(FORMAT_TYPES.API_DATE),
            toDate: date.format(FORMAT_TYPES.API_DATE)
        });
    };

    const handleFilterApply = () => {
        dispatch(getDailyReport(filters));
        dispatch(
            updateFilters({
                ...initialFilters,
                fisheryBoatIds: filters.fisheryBoatIds,
                fishNames: filters.fishNames,
                dailyDate: filters.fromDate
            })
        );
    };

    return (
        <div className="daily-report-filter-bar">
            <Title level={4}>{t('filterBar.title')}</Title>
            <Row className="md:text-base filter">
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
                    <div className="font-semibold">{t('filterBar.fisheryBoat.title')}</div>
                    <FBMultipleSelection
                        placeholder={t('filterBar.fisheryBoat.placeholder')}
                        listSelectItem={fisheryBoatsSelection}
                        onChange={handleFisheryBoatChange}
                        value={filters.fisheryBoatIds}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('filterBar.fish.title')}</div>
                    <FBMultipleSelection
                        placeholder={t('filterBar.fish.placeholder')}
                        listSelectItem={FISH_TYPES.map((type) => ({ ...type, text: t(type.text) }))}
                        onChange={handleFishNamesChange}
                        value={filters.fishNames}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('filterBar.date.title')}</div>
                    <FBDate
                        placeholder={t('filterBar.date.placeholder')}
                        onChange={handleDateChange}
                        defaultValue={
                            filters.fromDate &&
                            moment(
                                DateTimeUtils.formatDateTime(
                                    filters.fromDate,
                                    FORMAT_TYPES.NORWEGIAN_DATE
                                ),
                                FORMAT_TYPES.NORWEGIAN_DATE
                            )
                        }
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={3} className="flex items-end">
                    <button
                        className={`${CLASS_NAMES.BLUE_BUTTON} !mb-0`}
                        onClick={handleFilterApply}
                    >
                        {t('filterBar.apply')}
                    </button>
                </Col>
            </Row>
        </div>
    );
};

export default FilterBar;
