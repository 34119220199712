export const CLASS_NAMES = {
    BLUE_BUTTON:
        'bg-sky-700 hover:bg-sky-800 focus-within:bg-sky-700 hover:text-white text-white focus-within:text-white font-semibold py-1 px-2 rounded border-none mb-2 inline-flex items-center justify-center',
    GRAY_BUTTON:
        'bg-slate-500 hover:bg-slate-600 focus-within:bg-slate-500 text-white hover:text-white focus-within:text-white font-semibold py-1 px-2 rounded border-none inline-flex items-center justify-center',
    GREEN_BUTTON:
        'bg-cyan-800 hover:bg-cyan-900 focus-within:bg-cyan-800 text-white hover:text-white focus-within:text-white font-semibold py-1 px-2 rounded border-none inline-flex items-center justify-center',
    WHITE_BUTTON:
        'bg-white hover:bg-slate-50 hover:border-slate-400 text-slate-500 hover:text-slate-600 font-semibold py-1 px-2 rounded border-2 mb-2 inline-flex items-center justify-center',
    SILVER_BUTTON:
        'bg-slate-200 hover:bg-slate-300 text-slate-600 font-semibold py-1 px-2 rounded border-none outline-1 inline-flex items-center justify-center',
    PURPLE_BUTTON:
        'bg-slate-700 hover:bg-indigo-800 hover:border-slate-400 text-black font-semibold py-1 px-2 rounded border-2 mb-2 inline-flex items-center justify-center',

    SELECTED_BLUE_BUTTON:
        'bg-sky-700 focus-within:bg-sky-700 text-white focus-within:text-white font-semibold py-1 px-4 border border-sky-700 focus-within:border-sky-700 rounded cursor-not-allowed transition-all inline-flex items-center justify-center',
    UNSELECTED_BLUE_BUTTON:
        'bg-transparent hover:bg-gray-100 text-sky-700 font-semibold py-1 px-4 border border-sky-700 hover:border-sky-700 rounded transition-all inline-flex items-center justify-center',

    SELECTED_GRAY_BUTTON:
        'bg-slate-500 hover:bg-slate-600 text-white font-semibold py-1 px-4 rounded border border-gray-600 inline-flex items-center justify-center',
    UNSELECTED_GRAY_BUTTON:
        'bg-transparent hover:bg-gray-200 text-gray-700 font-semibold py-1 px-4 rounded border border-gray-600 inline-flex items-center justify-center'
};
