import { FBDropdownDownload, FBTable } from 'common/components';
import {
    API_ENDPOINTS,
    EXPORT_FILE_NAMES,
    EXPORT_FILE_TYPES,
    FORMAT_TYPES,
    Title
} from 'common/constants';
import { DateTimeUtils } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ReportTable = () => {
    const { t } = useTranslation();

    const { data: reports = [], loading = false } = useSelector((s) => s.dailyReport);
    const initialFilters = useSelector((s) => s.filters.data);

    const COLUMNS_FOR_DAILY_REPORT = [
        {
            title: 'table.fisheryBoat',
            dataIndex: 'fisheryBoat'
        },
        {
            title: 'table.startTime',
            dataIndex: 'startTime',
            render: (startTime) =>
                DateTimeUtils.formatDateTime(startTime, FORMAT_TYPES.NORWEGIAN_DATE_TIME),
            sorter: {
                compare: (a, b) => new Date(a.startTime) - new Date(b.startTime),
                multiple: 2
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: 'table.stopTime',
            dataIndex: 'stopTime',
            render: (stopTime) =>
                DateTimeUtils.formatDateTime(stopTime, FORMAT_TYPES.NORWEGIAN_DATE_TIME),
            sorter: {
                compare: (a, b) => new Date(a.stopTime) - new Date(b.stopTime),
                multiple: 1
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: 'fishCatch.torsk',
            dataIndex: 'torsk',
            width: 120,
            sorter: {
                compare: (a, b) => a.torsk - b.torsk,
                multiple: 3
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: 'fishCatch.hyse',
            dataIndex: 'hyse',
            width: 120,
            sorter: {
                compare: (a, b) => a.hyse - b.hyse,
                multiple: 4
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: 'fishCatch.sei',
            dataIndex: 'sei',
            width: 120,
            sorter: {
                compare: (a, b) => a.sei - b.sei,
                multiple: 5
            },
            sortDirections: ['ascend', 'descend']
        }
    ];

    const downloadOptions = [
        {
            label: t('download.currentFilter'),
            params: {
                fisheryBoatIds: initialFilters.fisheryBoatIds,
                fishNames: initialFilters.fishNames,
                fromDate: initialFilters.dailyDate,
                toDate: initialFilters.dailyDate,
                exportType: EXPORT_FILE_TYPES.CURRENT_FILTER
            }
        },
        {
            label: t('download.allData'),
            params: {
                fromDate: initialFilters.dailyDate,
                toDate: initialFilters.dailyDate,
                exportType: EXPORT_FILE_TYPES.ALL
            }
        }
    ];

    // filter the data to match the table
    const filteredReports = reports.map((item, index) => {
        let obj = Object.assign(
            { key: index },
            item,
            item.fishes.reduce(
                (accumulator, currentValue) => ({
                    ...accumulator,
                    [currentValue.fishId]: currentValue.dead
                }),
                {}
            )
        );

        delete obj.fishes;

        return obj;
    });

    // filter columns with api response
    const filteredColumns = () => {
        if (!filteredReports.length) return COLUMNS_FOR_DAILY_REPORT;

        return COLUMNS_FOR_DAILY_REPORT.filter((col) =>
            filteredReports[0].hasOwnProperty(col.dataIndex)
        );
    };

    return (
        <div className="daily-report-table mt-5">
            <div className="flex justify-between">
                <Title level={4}>{t('daily.title')}</Title>
                <FBDropdownDownload
                    options={downloadOptions}
                    exportApi={API_ENDPOINTS.DAILY_REPORT_EXPORT}
                    fileName={EXPORT_FILE_NAMES.DAILY_REPORT}
                    method={'GET'}
                />
            </div>

            <FBTable
                columns={filteredColumns()}
                className="report-table"
                rowClassName="table-row"
                loading={loading}
                data={filteredReports}
            />
        </div>
    );
};

export default ReportTable;
