import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import './FBSwitch.scss';

const FBSwitch = ({
    name,
    checked = false,
    dataTestId,
    onChange = () => {},
    handleSwitchChange = () => {}
}) => {
    const { t } = useTranslation();

    return (
        <Switch
            checkedChildren={t(name)}
            unCheckedChildren={t(name)}
            className="mt-2 mr-6 font-medium switch"
            onClick={() => handleSwitchChange(name)}
            checked={checked}
            onChange={(checked) => onChange(checked)}
            data-testid={dataTestId}
        />
    );
};

export default FBSwitch;
