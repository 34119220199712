import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { ROLES } from 'common/constants';

const defineAbilityFor = (groups = []) => {
    const { build, can } = new AbilityBuilder(createMongoAbility);
    groups.forEach((group, _) => {
        switch (group) {
            case ROLES.ADMINISTRATOR:
                can('manage', ROLES.ADMINISTRATOR, ['all']);
                can('manage', ROLES.CAPTAIN, ['all']);
                can('manage', ROLES.USER, ['all']);
                break;
            case ROLES.CAPTAIN:
                can('manage', ROLES.CAPTAIN, ['all']);
                can('manage', ROLES.USER, ['all']);
                break;
            case ROLES.USER:
                can('manage', ROLES.USER, ['all']);
                break;
            default:
                break;
        }
    });

    return build();
};

export default defineAbilityFor;
