import { createSlice } from '@reduxjs/toolkit';
import { getFisheryBoatDetail } from 'redux/thunks';

const initialState = {
    data: {},
    loading: false
};

export const fisheryBoatDetailSlice = createSlice({
    name: 'fisheryBoatDetail',
    initialState,
    reducers: {},
    extraReducers: {
        [getFisheryBoatDetail.pending]: (state) => {
            state.loading = true;
        },
        [getFisheryBoatDetail.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getFisheryBoatDetail.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
