import { Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const FBMultipleSelection = ({
    showSearch = true,
    listSelectItem = [],
    className,
    placeholder,
    defaultValue,
    value,
    dataTestId,
    onChange
}) => {
    const { t } = useTranslation();

    const handleChange = (values, objects) => {
        if (onChange) {
            onChange(
                values,
                objects.map((object) => ({
                    id: object.value,
                    text: object.children,
                    selectionObject: object.selectionObject
                }))
            );
        }
    };

    return (
        <Select
            mode="multiple"
            allowClear
            style={{
                width: '100%'
            }}
            placeholder={placeholder || t('filterBar.selectionPlaceholder')}
            filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleChange}
            defaultValue={defaultValue}
            className={className}
            showArrow
            showSearch={showSearch}
            value={value}
        >
            {listSelectItem.map((item) => (
                <Select.Option
                    value={item.id}
                    key={item.id}
                    data-testid={dataTestId}
                    selectionObject={item.selectionObject}
                >
                    {item.text}
                </Select.Option>
            ))}
        </Select>
    );
};

FBMultipleSelection.propTypes = {
    showSearch: PropTypes.bool,
    listSelectItem: PropTypes.array,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    mode: PropTypes.string,
    dataTestId: PropTypes.string,
    onChange: PropTypes.func
};

export default FBMultipleSelection;
