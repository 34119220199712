import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
    dailyReportSlice,
    filtersSlice,
    fishCatchCardSlice,
    fishCatchChartSlice,
    fishCatchCountingChartSlice,
    fishCatchCountingSlice,
    fishCatchDailyCountingSlice,
    fisheryBoatDetailSlice,
    fisheryBoatListSlice,
    fisheryBoatSlice,
    fisheryBoatsSelectionSlice,
    profileSlice,
    userSlice
} from './slices';

const store = configureStore({
    reducer: {
        dailyReport: dailyReportSlice.reducer,
        filters: filtersSlice.reducer,
        fishCatchCard: fishCatchCardSlice.reducer,
        fishCatchChart: fishCatchChartSlice.reducer,
        fishCatchCountingChart: fishCatchCountingChartSlice.reducer,
        fishCatchCounting: fishCatchCountingSlice.reducer,
        fishCatchDailyCounting: fishCatchDailyCountingSlice.reducer,
        fisheryBoatDetail: fisheryBoatDetailSlice.reducer,
        fisheryBoatList: fisheryBoatListSlice.reducer,
        fisheryBoat: fisheryBoatSlice.reducer,
        fisheryBoatsSelection: fisheryBoatsSelectionSlice.reducer,
        profile: profileSlice.reducer,
        user: userSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false // Disable serializable check
        }),
    devTools: process.env.NODE_ENV !== 'production'
});

export const useAppDispatch = () => useDispatch();

export default store;
