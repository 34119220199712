export const API_ENDPOINTS = {
    // selection API
    FISHERY_BOATS_SELECTION: 'api/selection/fishery-boats',

    PROFILE: 'api/me/profile',

    SETUP_ACCOUNT: 'api/app/setup-account',

    USER: 'api/users',

    FISH_CATCH_CARD: 'api/reports/fish-catch-card',
    FISH_CATCH_CHART: 'api/reports/fish-catch-chart',

    FISH_CATCH_COUNTING_CHART: 'api/reports/fish-catch-counting-chart',
    FISH_CATCH_COUNTING: 'api/reports/fish-catch-counting',

    EXPORT_FISH_CATCH_COUNTING: 'api/exports/fish-catch-counting',

    FISHERY_BOAT_LIST: 'api/fishery-boats/list',
    FISHERY_BOAT: 'api/fishery-boats',

    DAILY_REPORT: 'api/reports/daily',
    DAILY_REPORT_EXPORT: 'api/exports/daily'
};
