import { Card, Col, Row, Spin, Statistic } from 'antd';
import { FBColumn } from 'common/components';
import { CLASS_NAMES, FORMAT_TYPES, GRAPH_TYPES } from 'common/constants';
import { DateTimeUtils } from 'common/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getFishCatchCountingChart, updateFilters } from 'redux/thunks';
import './FishMortality.scss';

const FishMortality = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const filters = useSelector((s) => s.filters.data);
    const { data, loading } = useSelector((s) => s.fishCatchCountingChart);

    useEffect(() => {
        dispatch(
            getFishCatchCountingChart({
                fisheryBoatIds: filters.fisheryBoatIds,
                fishNames: filters.fishNames,
                fromDate: filters.fromDate,
                toDate: filters.toDate,
                graphType: filters.graphType
            })
        );
    }, [
        filters.fisheryBoatIds,
        filters.fishNames,
        filters.fromDate,
        filters.toDate,
        filters.graphType
    ]);

    const handleGraphTypeChange = (newType) => {
        dispatch(
            updateFilters({
                ...filters,
                graphType: newType
            })
        );
    };

    return (
        <div className={`fish-mortality-report mt-5`}>
            <h2 className="mb-2">{t('fishCatch.title')}</h2>
            <Spin spinning={loading}>
                <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                        <Row gutter={[8, 8]} className="h-full">
                            {data.counts?.map((item, index) => {
                                return (
                                    <Col
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={24}
                                        xl={24}
                                        key={index}
                                        className={item.fishName}
                                    >
                                        <Card className="flex justify-center content-center h-full card-count">
                                            <Statistic
                                                title={t(`fishCatch.${item.fishName}`)}
                                                value={item.totalAmount}
                                                valueStyle={{
                                                    color: '#cf1322'
                                                }}
                                            />
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                        <Card>
                            <div className="flex justify-end">
                                <button
                                    className={
                                        filters?.graphType === GRAPH_TYPES.AGGREGATE
                                            ? CLASS_NAMES.SELECTED_BLUE_BUTTON
                                            : CLASS_NAMES.UNSELECTED_BLUE_BUTTON
                                    }
                                    onClick={() => handleGraphTypeChange(GRAPH_TYPES.AGGREGATE)}
                                >
                                    {t('catchChart.aggregate')}
                                </button>
                                &nbsp;
                                <button
                                    className={
                                        filters?.graphType === GRAPH_TYPES.PER_DAY
                                            ? CLASS_NAMES.SELECTED_BLUE_BUTTON
                                            : CLASS_NAMES.UNSELECTED_BLUE_BUTTON
                                    }
                                    onClick={() => handleGraphTypeChange(GRAPH_TYPES.PER_DAY)}
                                >
                                    {t('catchChart.perDay')}
                                </button>
                            </div>

                            <FBColumn
                                data={data.charts
                                    ?.filter((chart) => chart.name !== 'total')
                                    .map((chart) => ({
                                        type: t(`fishCatch.${chart.name}`),
                                        value: chart.amount,
                                        time: DateTimeUtils.formatDateTime(
                                            chart.day,
                                            FORMAT_TYPES.NORWEGIAN_DATE
                                        )
                                    }))}
                                isStack={true}
                            />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default FishMortality;
