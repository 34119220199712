import { AlignLeftOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Layout as LibLayout, Spin } from 'antd';
import fishwellLogo from 'assets/Fishwell_Technology_Logo.png';
import { FBErrorBoundary } from 'common/components';
import { API_ENDPOINTS } from 'common/constants';
import { alertErrorMessage } from 'common/utils';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import get from 'lodash/get';
import { useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { getCurrentProfile, getFisheryBoatListOnNavbar } from 'redux/thunks';
import { API } from 'services';
import Footer from './Footer';
import Header from './Header';
import './Layout.scss';
import Sidebar from './Sidebar';

const { Content } = LibLayout;

const Layout = () => {
    const { accounts } = useMsal();
    const dispatch = useAppDispatch();

    const organizationId = useSelector((s) => get(s, 'profile.data.company.organizationId', ''));

    const [isSetupAccountLoading, setIsSetupAccountLoading] = useState(true);
    const [collapsed] = useState(false);

    const ldProviderConfig = {
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
        context: {
            kind: 'multi',
            Tenant: {
                key: get(accounts[0], 'tenantId', ''),
                name: 'Tenant'
            },
            ...(organizationId && {
                OrganizationID: {
                    key: organizationId,
                    name: 'OrganizationID'
                }
            })
        }
    };

    const containerStyle = useMemo(() => ({ marginLeft: collapsed ? 80 : 250 }), [collapsed]);

    const handleToggleMenu = (isToggleIcon = false) => {
        const sidebar = document.getElementsByClassName('sidebar')[0];
        const container = document.getElementsByClassName('main')[0];

        if (sidebar.classList.contains('sidebar-responsive')) {
            sidebar.classList.remove('sidebar-responsive');
            container.classList.remove('main-blur');
        } else if (isToggleIcon) {
            sidebar.classList.add('sidebar-responsive');
            container.classList.add('main-blur');
        }
    };

    useEffect(() => {
        const setupAccount = async () => {
            try {
                await API.post(API_ENDPOINTS.SETUP_ACCOUNT);
                await dispatch(getCurrentProfile()).unwrap();
                dispatch(getFisheryBoatListOnNavbar());
                setIsSetupAccountLoading(false);
            } catch (error) {
                alertErrorMessage(error);
            }
        };

        setupAccount();
    }, []);

    if (isSetupAccountLoading) {
        return (
            <div className="min-h-[100vh] bg-white flex flex-col justify-center items-center">
                <img src={fishwellLogo} alt="Fishwell Technology Logo" className="w-72" />
                <Spin></Spin>
            </div>
        );
    }

    const renderLayout = () => {
        return (
            <LibLayout hasSider className="layout min-h-screen">
                <Sidebar />

                <AlignLeftOutlined className="toggle-icon" onClick={() => handleToggleMenu(true)} />

                <LibLayout
                    className="layout-container"
                    style={containerStyle}
                    onClick={() => handleToggleMenu()}
                >
                    <Header />

                    <Content className="main pt-3">
                        <ErrorBoundary FallbackComponent={FBErrorBoundary}>
                            <Outlet />
                        </ErrorBoundary>
                    </Content>

                    <Footer />
                </LibLayout>
            </LibLayout>
        );
    };

    const LDProvider = withLDProvider(ldProviderConfig)(renderLayout);
    return <LDProvider />;
};
export default Layout;
