import { Card, Spin } from 'antd';
import { FBColumn, FBSwitch } from 'common/components';
import { CLASS_NAMES, FISH_TYPES, FORMAT_TYPES, GRAPH_TYPES, PAGE_URLS } from 'common/constants';
import { DateTimeUtils } from 'common/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { getFishCatchChart, updateFilters } from 'redux/thunks';

const FishCatchChart = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { fisheryBoatId } = useParams();

    const filters = useSelector((s) => s.filters.data);
    const fishCatchCharts = useSelector((s) => s.fishCatchChart.data);
    const cardLoading = useSelector((s) => s.fishCatchCard.loading);
    const chartLoading = useSelector((s) => s.fishCatchChart.loading);

    useEffect(() => {
        dispatch(
            getFishCatchChart({
                fisheryBoatIds: fisheryBoatId ? [fisheryBoatId] : [],
                fishNames: filters.fishNames,
                fromDate: filters.timePeriod.fromDate,
                graphType: filters.graphType
            })
        );
    }, [fisheryBoatId, filters.fishNames, filters.timePeriod.fromDate, filters.graphType]);

    // handle fish types change
    const handleSwitchChange = (name) => {
        let newFishNames;
        if (filters.fishNames.includes(name)) {
            newFishNames = filters.fishNames.filter((fishName) => fishName !== name);
        } else {
            newFishNames = [...filters.fishNames, name];
        }

        dispatch(
            updateFilters({
                ...filters,
                fishNames: newFishNames
            })
        );
    };

    // handle chart type change
    const handleGraphTypeChange = (newType) => {
        dispatch(
            updateFilters({
                ...filters,
                graphType: newType
            })
        );
    };

    return (
        <Card bordered className="mt-4">
            <div className="flex justify-between xs:items-center lg:items-end xs:flex-col lg:flex-row pb-5">
                <div>
                    <p className="font-medium text-base m-0">{t('catchChart.fishType')}</p>
                    {FISH_TYPES.map((type) => {
                        return (
                            <FBSwitch
                                key={type.id}
                                name={type.text}
                                handleSwitchChange={() => handleSwitchChange(type.id)}
                                className="mt-2"
                                checked={filters.fishNames?.includes(type.id)}
                            />
                        );
                    })}
                </div>

                <div className="xs:mt-4 lg:mt-0">
                    <button
                        className={
                            filters.graphType === GRAPH_TYPES.AGGREGATE
                                ? CLASS_NAMES.SELECTED_BLUE_BUTTON
                                : CLASS_NAMES.UNSELECTED_BLUE_BUTTON
                        }
                        onClick={() => handleGraphTypeChange(GRAPH_TYPES.AGGREGATE)}
                    >
                        {t('catchChart.aggregate')}
                    </button>
                    &nbsp;
                    <button
                        className={
                            filters.graphType === GRAPH_TYPES.PER_DAY
                                ? CLASS_NAMES.SELECTED_BLUE_BUTTON
                                : CLASS_NAMES.UNSELECTED_BLUE_BUTTON
                        }
                        onClick={() => handleGraphTypeChange(GRAPH_TYPES.PER_DAY)}
                    >
                        {t('catchChart.perDay')}
                    </button>
                </div>
            </div>

            <Spin spinning={!cardLoading && chartLoading}>
                <div
                    className="cursor-pointer"
                    onClick={async () => {
                        await dispatch(
                            updateFilters({
                                ...filters,
                                fisheryBoatIds: fisheryBoatId ? [fisheryBoatId] : []
                            })
                        ).unwrap();

                        navigate(PAGE_URLS.MORTALITY);
                    }}
                >
                    <FBColumn
                        data={fishCatchCharts
                            .filter((chart) => chart.name !== 'total')
                            .map((chart) => ({
                                type: t(`fishCatch.${chart.name}`),
                                value: chart.amount,
                                time: DateTimeUtils.formatDateTime(
                                    chart.day,
                                    FORMAT_TYPES.NORWEGIAN_DATE
                                )
                            }))}
                        isStack={true}
                    />
                </div>
            </Spin>
        </Card>
    );
};

export default FishCatchChart;
