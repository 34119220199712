import { FORMAT_TYPES } from 'common/constants';
import moment from 'moment';

const formatDateTime = (value, format = FORMAT_TYPES.DATE_TIME) => {
    if (!value) return;
    return moment(value).format(format);
};

const formatTime = (timer = 0) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
};
const getPreviousDate = (n = 0) => {
    const today = new Date();
    return new Date(today.setDate(today.getDate() - n));
};

const convertUtcToLocalTime = (utcTimeString) => {
    if (!utcTimeString) return '';

    const utcTime = new Date(utcTimeString);
    const offset = new Date().getTimezoneOffset();
    const localTime = new Date(utcTime.getTime() - offset * 60000);

    return localTime;
};

const getTimeAgo = (utcTimeString) => {
    const localTime = convertUtcToLocalTime(utcTimeString);
    const timeAgo = moment(localTime).fromNow();

    return timeAgo;
};

const getTimeAgoByStringDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return getTimeAgo(date);
};

const calculateTimeDifference = (startTime, stopTime, outputFormat = 'HH:mm') => {
    if (!startTime || !stopTime) {
        return '-';
    }
    const start = moment(startTime);
    const stop = moment(stopTime);

    const duration = moment.duration(stop.diff(start));
    const hours = duration.hours();
    const minutes = duration.minutes();

    return moment().startOf('day').add({ hours, minutes }).format(outputFormat);
};

export const DateTimeUtils = {
    formatDateTime,
    formatTime,
    getPreviousDate,
    getTimeAgo,
    calculateTimeDifference,
    getTimeAgoByStringDateTime
};
