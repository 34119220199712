import {
    DeleteOutlined,
    EditOutlined,
    GlobalOutlined,
    PlusOutlined,
    RestOutlined,
    RollbackOutlined
} from '@ant-design/icons';
import { Button, Space, Spin, Tabs } from 'antd';
import { FBTable } from 'common/components';
import { ALERT_MESSAGES, CLASS_NAMES, SWAL_CONFIGS, Title } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import {
    deleteFisheryBoat,
    getFisheryBoatListOnNavbar,
    getFisheryBoats,
    restoreFisheryBoat
} from 'redux/thunks';
import Swal from 'sweetalert2';
import FisheryBoatForm from './FisheryBoatForm';

const FisheryBoat = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { data: boats = [], loading = false } = useSelector((s) => s.fisheryBoat);

    const [isDeleteTab, setIsDeleteTab] = useState(false);
    const [modalState, setModalState] = useState({ open: false, boat: null });

    const columns = [
        {
            dataIndex: 'name',
            className: 'name',
            title: 'fisheryBoat.table.name',
            sortDirections: ['ascend', 'descend'],
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 1
            }
        },
        {
            dataIndex: 'description',
            className: 'description',
            title: 'fisheryBoat.table.description'
        },
        {
            dataIndex: 'externalBoatId',
            className: 'externalBoatId',
            title: 'fisheryBoat.table.externalBoatId'
        },
        {
            dataIndex: 'action',
            className: 'action',
            title: 'fisheryBoat.table.action',
            render: (_, record) => (
                <Space size="middle">
                    {isDeleteTab ? (
                        <Button
                            className={CLASS_NAMES.BLUE_BUTTON}
                            onClick={() => onRestore(record.id)}
                        >
                            <RollbackOutlined />
                            <span>{t('button.restore')}</span>
                        </Button>
                    ) : (
                        <>
                            <Button
                                className={CLASS_NAMES.GRAY_BUTTON}
                                onClick={() => handleModalShow(record)}
                            >
                                <EditOutlined />
                                <span>{t('button.edit')}</span>
                            </Button>
                            <Button
                                className={CLASS_NAMES.GREEN_BUTTON}
                                onClick={() => onDelete(record.id)}
                            >
                                <DeleteOutlined />
                                <span>{t('button.delete')}</span>
                            </Button>
                        </>
                    )}
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getFisheryBoats({ isDeleted: isDeleteTab }));
    }, [isDeleteTab]);

    const handleTabChange = (isDeleted) => setIsDeleteTab(isDeleted);

    const handleReload = (isDeleted) => {
        if (isDeleteTab === isDeleted) dispatch(getFisheryBoats({ isDeleted: isDeleted }));
        setIsDeleteTab(isDeleted);
    };

    const handleModalClose = () => setModalState({ open: false, boat: null });

    const handleModalShow = (boat) => setModalState({ open: true, boat });

    const onDelete = (id) => {
        Swal.fire(SWAL_CONFIGS.DELETE).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteFisheryBoat(id)).unwrap();

                    handleReload(isDeleteTab);
                    alertSuccessMessage(t(ALERT_MESSAGES.SUCCESSFULLY_DELETION));
                    dispatch(getFisheryBoatListOnNavbar());
                } catch (err) {
                    alertErrorMessage(err);
                }
            }
        });
    };

    const onRestore = (id) => {
        Swal.fire(SWAL_CONFIGS.RESTORE).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(restoreFisheryBoat(id)).unwrap();

                    handleReload(isDeleteTab);
                    alertSuccessMessage(t(ALERT_MESSAGES.SUCCESSFULLY_RESTORE));
                    dispatch(getFisheryBoatListOnNavbar());
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });
    };

    return (
        <>
            <Title level={4}>{t('fisheryBoat.title')}</Title>
            <div>
                <Button className={CLASS_NAMES.BLUE_BUTTON} onClick={() => handleModalShow(null)}>
                    <PlusOutlined />
                    <span>{t('fisheryBoat.newFisheryBoatBtn')}</span>
                </Button>
            </div>

            <Spin spinning={loading}>
                <Tabs
                    activeKey={isDeleteTab}
                    animated={true}
                    onChange={handleTabChange}
                    items={[
                        {
                            label: (
                                <span>
                                    <GlobalOutlined />
                                    <span>{t('fisheryBoat.activeFisheryBoats')}</span>
                                </span>
                            ),
                            key: false,
                            children: (
                                <FBTable
                                    columns={columns}
                                    data={boats}
                                    totalRecords={boats.length}
                                    rowKey="id"
                                />
                            ),
                            disabled: loading
                        },
                        {
                            label: (
                                <span>
                                    <RestOutlined />
                                    <span>{t('fisheryBoat.deletedFisheryBoats')}</span>
                                </span>
                            ),
                            key: true,
                            children: (
                                <FBTable
                                    columns={columns}
                                    data={boats}
                                    totalRecords={boats.length}
                                    rowKey="id"
                                />
                            ),
                            disabled: loading
                        }
                    ]}
                />
            </Spin>

            <FisheryBoatForm
                open={modalState.open}
                boat={modalState.boat}
                onClose={handleModalClose}
                onReload={handleReload}
            />
        </>
    );
};

export default FisheryBoat;
