import { createSlice } from '@reduxjs/toolkit';
import {
    createFisheryBoat,
    deleteFisheryBoat,
    getFisheryBoats,
    restoreFisheryBoat,
    updateFisheryBoat
} from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const fisheryBoatSlice = createSlice({
    name: 'fisheryBoat',
    initialState,
    reducers: {},
    extraReducers: {
        [getFisheryBoats.pending]: (state) => {
            state.loading = true;
        },
        [getFisheryBoats.fulfilled]: (state, action) => {
            state.data = action.payload || initialState.data;
            state.loading = false;
        },
        [getFisheryBoats.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        },

        [createFisheryBoat.pending]: (state) => {
            state.loading = true;
        },
        [createFisheryBoat.fulfilled]: (state) => {
            state.loading = false;
        },
        [createFisheryBoat.rejected]: (state) => {
            state.loading = false;
        },

        [updateFisheryBoat.pending]: (state) => {
            state.loading = true;
        },
        [updateFisheryBoat.fulfilled]: (state) => {
            state.loading = false;
        },
        [updateFisheryBoat.rejected]: (state) => {
            state.loading = false;
        },

        [deleteFisheryBoat.pending]: (state) => {
            state.loading = true;
        },
        [deleteFisheryBoat.fulfilled]: (state) => {
            state.loading = false;
        },
        [deleteFisheryBoat.rejected]: (state) => {
            state.loading = false;
        },

        [restoreFisheryBoat.pending]: (state) => {
            state.loading = true;
        },
        [restoreFisheryBoat.fulfilled]: (state) => {
            state.loading = false;
        },
        [restoreFisheryBoat.rejected]: (state) => {
            state.loading = false;
        }
    }
});
