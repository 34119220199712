import React from 'react';
import FilterBar from './FilterBar';
import ReportTable from './ReportTable';

const Daily = () => {
    return (
        <div className="daily-report">
            <FilterBar />
            <ReportTable />
        </div>
    );
};

export default Daily;
