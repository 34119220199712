import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_ENDPOINTS } from 'common/constants';
import { API } from 'services';

export const getFishCatchCard = createAsyncThunk(
    'fishCatch/getFishCatchCard',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(API_ENDPOINTS.FISH_CATCH_CARD, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFishCatchChart = createAsyncThunk(
    'fishCatch/getFishCatchChart',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(API_ENDPOINTS.FISH_CATCH_CHART, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFishCatchDailyCounting = createAsyncThunk(
    'fishCatch/getFishCatchDailyCounting',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(API_ENDPOINTS.FISH_CATCH_CARD, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFishCatchCountingChart = createAsyncThunk(
    'fishCatch/getFishCatchCountingChart',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(API_ENDPOINTS.FISH_CATCH_COUNTING_CHART, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFishCatchCounting = createAsyncThunk(
    'fishCatch/getFishCatchCounting',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(API_ENDPOINTS.FISH_CATCH_COUNTING, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
