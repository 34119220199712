import { createSlice } from '@reduxjs/toolkit';
import { getFishCatchCard } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const fishCatchCardSlice = createSlice({
    name: 'fishCatchCard',
    initialState,
    reducers: {},
    extraReducers: {
        [getFishCatchCard.pending]: (state) => {
            state.loading = true;
        },
        [getFishCatchCard.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getFishCatchCard.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
