import { Button, Modal, Spin, Tree } from 'antd';
import 'antd/dist/antd.min.css';
import { ALERT_MESSAGES } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getUsers, updateUser } from 'redux/thunks';
import './index.scss';

const UserForm = ({ open = false, user, onClose = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const updateLoading = useSelector((s) => s.user.loading || false);
    const { data: boatList } = useSelector((s) => s.fisheryBoatList);

    const [fisheryBoatIds, setFisheryBoatIds] = useState([]);

    useEffect(() => {
        setFisheryBoatIds(user?.fisheryBoatIds);
    }, [user]);

    const handleUpdateUser = async () => {
        try {
            await dispatch(updateUser({ id: user.id, item: { fisheryBoatIds } })).unwrap();

            dispatch(getUsers());
            alertSuccessMessage(ALERT_MESSAGES.SUCCESSFULLY_UPDATE);
            onClose();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    return (
        <Modal
            title={t('user.table.assign')}
            open={open}
            onCancel={() => onClose()}
            width={300}
            footer={
                <>
                    <Button type="default" onClick={() => onClose()} className="m-1">
                        {t('button.cancel')}
                    </Button>
                    <Button
                        onClick={handleUpdateUser}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {t('button.saveChange')}
                    </Button>
                </>
            }
        >
            <Spin spinning={updateLoading}>
                <Tree
                    className="user-tree"
                    checkable={true}
                    onCheck={(selectedKeys) => setFisheryBoatIds(selectedKeys)}
                    checkedKeys={fisheryBoatIds}
                    treeData={boatList.map((boat) => ({
                        key: boat.id,
                        title: boat.name
                    }))}
                />
            </Spin>
        </Modal>
    );
};

UserForm.propTypes = {
    open: PropTypes.bool,
    user: PropTypes.object,
    onClose: PropTypes.func
};

export default UserForm;
