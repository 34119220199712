import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_ENDPOINTS } from 'common/constants';
import { API } from 'services';

export const getDailyReport = createAsyncThunk(
    'reports/getDailyReport',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(API_ENDPOINTS.DAILY_REPORT, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);
