import { DatePicker, Space } from 'antd';
import './FBRangeDate.scss';
import { FORMAT_TYPES } from 'common/constants';

const { RangePicker } = DatePicker;

const FBRangeDate = ({ onChange = null, defaultValue, value, placeholder }) => {
    const onDateChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <RangePicker
                style={{ width: '100%' }}
                onChange={onDateChange}
                format={FORMAT_TYPES.NORWEGIAN_DATE}
                defaultValue={defaultValue}
                value={value}
                placeholder={placeholder}
            />
        </Space>
    );
};

export default FBRangeDate;
