import { useMsal } from '@azure/msal-react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import { Button, Result } from 'antd';
import { CLASS_NAMES } from 'common/constants';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect();
    };

    return (
        <div className="bg-white min-h-[100vh]">
            <Result
                status="500"
                title={t('unauthorized.title')}
                subTitle={t('unauthorized.subTitle')}
                extra={
                    <div className="flex justify-center gap-x-2">
                        <Button className={CLASS_NAMES.GRAY_BUTTON} onClick={handleLogout}>
                            {t('button.logout')}
                        </Button>
                    </div>
                }
            />
        </div>
    );
};

export default withAITracking(reactPlugin, Unauthorized);
