import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import { FishCatch } from 'components';
import BoatList from './BoatList';
import GeneralInfo from './GeneralInfo';

const DashBoard = () => {
    return (
        <div className="dashboard">
            <GeneralInfo />
            <FishCatch />
            <BoatList />
        </div>
    );
};

export default withAITracking(reactPlugin, DashBoard);
