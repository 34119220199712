import { Layout as LibLayout } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './Footer.scss';
import { FORMAT_TYPES } from 'common/constants';

const Footer = () => {
    const { t } = useTranslation();

    const { Footer } = LibLayout;

    return (
        <Footer className="footer">
            {`Fishwell Fishery ${moment().format(FORMAT_TYPES.YEAR)} ${t(
                'layout.footer.createdBy'
            )} Fishwell Technology`}
        </Footer>
    );
};

export default Footer;
