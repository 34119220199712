export const FORMAT_TYPES = {
    DATE_TIME: 'DD/MM/YYYY HH:mm:ss',
    DATE: 'DD/MM/YYYY',
    TIME: 'HH:mm:ss',
    YEAR: 'YYYY',

    NORWEGIAN_DATE: 'DD.MM.YYYY',
    NORWEGIAN_DATE_TIME: 'DD.MM.YYYY HH.mm',

    API_DATE: 'YYYY-MM-DD',
    FILE_EXPORT_DATE: 'YYYYMMDDHHmmss'
};
