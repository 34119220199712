import { Spin } from 'antd';
import { FBSelection } from 'common/components';
import { FORMAT_TYPES, TIME_PERIODS, TIME_PERIOD_DEFAULT } from 'common/constants';
import { DateTimeUtils } from 'common/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { updateFilters } from 'redux/thunks';
import './FishCatch.scss';
import FishCatchCard from './FishCatchCard';
import FishCatchChart from './FishCatchChart';

const FishCatch = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const filters = useSelector((s) => s.filters.data);
    const cardLoading = useSelector((s) => s.fishCatchCard.loading);
    const chartLoading = useSelector((s) => s.fishCatchChart.loading);

    useEffect(() => {
        dispatch(
            updateFilters({
                ...filters,
                fromDate: filters.timePeriod.fromDate,
                toDate: DateTimeUtils.formatDateTime(new Date(), FORMAT_TYPES.API_DATE)
            })
        );
    }, []);

    const handlePeriodChange = (value, object) => {
        const timePeriod = {
            value,
            fromDate: object.from
                ? DateTimeUtils.formatDateTime(object.from, FORMAT_TYPES.API_DATE)
                : null
        };

        dispatch(
            updateFilters({
                ...filters,
                timePeriod,
                fromDate: timePeriod.fromDate,
                toDate: object.from
                    ? DateTimeUtils.formatDateTime(new Date(), FORMAT_TYPES.API_DATE)
                    : null
            })
        );
    };

    return (
        <div className={`fish-catch mt-5`}>
            <div className="title mb-2">
                <h2 className="inline mr-1">{t('fishCatch.title')}</h2>
                <FBSelection
                    placeholder={t('fishCatch.selectPeriod')}
                    listSelectItem={TIME_PERIODS.map((period) => ({
                        ...period,
                        text: t(period.text)
                    }))}
                    defaultValue={TIME_PERIOD_DEFAULT.id}
                    className="w-40"
                    onChange={handlePeriodChange}
                    value={filters.timePeriod.value}
                    disabled={cardLoading || chartLoading}
                />
            </div>
            <Spin spinning={cardLoading && chartLoading}>
                <FishCatchCard />
                <FishCatchChart />
            </Spin>
        </div>
    );
};

export default FishCatch;
