import { EditOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import { Avatar, Button, Card, Descriptions, Image, Spin } from 'antd';
import defaultAvatar from 'assets/Default_Avatar.png';
import defaultCompanyLogo from 'assets/Default_Company_Logo.jpg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getCurrentProfile } from 'redux/thunks';
import './Profile.scss';
import UpdateProfile from './UpdateProfile';

const Profile = () => {
    const { t } = useTranslation();
    const { accounts } = useMsal();
    const dispatch = useAppDispatch();

    const { data: profile, loading } = useSelector((s) => s.profile);

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        dispatch(getCurrentProfile());
    }, []);

    return (
        <div className="profile">
            <div className="profile-nav-bg" />
            <Card
                className="card-profile-head"
                bodyStyle={{ display: 'none' }}
                loading={loading}
                title={
                    <div className="flex justify-between">
                        <Avatar.Group>
                            <Avatar
                                size={74}
                                shape="square"
                                src={profile.avatar || defaultAvatar}
                            />

                            <div className="avatar-info">
                                <h4 className="font-semibold m-0">{profile.name}</h4>
                                <p>{accounts[0]?.idTokenClaims?.roles}</p>
                            </div>
                        </Avatar.Group>
                        <Image
                            className="object-contain"
                            width={75}
                            height={75}
                            src={profile.company.logoUrl || defaultCompanyLogo}
                        />
                    </div>
                }
            />

            <Spin spinning={loading}>
                <Card
                    bordered={false}
                    title={
                        <h6 className="font-semibold m-0">
                            {t('profile.information.profileTitle')}
                        </h6>
                    }
                    className="header-solid h-full card-profile-information"
                    extra={
                        <Button onClick={() => setIsModalOpen(true)} type="link">
                            <EditOutlined className="text-lg" />
                        </Button>
                    }
                    bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                >
                    <p className="text-dark">
                        {profile.bio || (
                            <span className="text-neutral-400 italic">
                                {t('profile.information.notUpdatedYet')}
                            </span>
                        )}
                    </p>
                    <hr className="my-25" />
                    <Descriptions title={t('profile.information.generalTitle')}>
                        <Descriptions.Item label={t('profile.information.fullName')} span={3}>
                            {profile.name || (
                                <span className="text-neutral-400 italic">
                                    {t('profile.information.notUpdatedYet')}
                                </span>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('profile.information.company')} span={3}>
                            {profile.company.name || (
                                <span className="text-neutral-400 italic">
                                    {t('profile.information.notUpdatedYet')}
                                </span>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('profile.information.phoneNumber')} span={3}>
                            {profile.phoneNumber || (
                                <span className="text-neutral-400 italic">
                                    {t('profile.information.notUpdatedYet')}
                                </span>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('profile.information.email')} span={3}>
                            {profile.email || (
                                <span className="text-neutral-400 italic">
                                    {t('profile.information.notUpdatedYet')}
                                </span>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('profile.information.address')} span={3}>
                            {profile.address || (
                                <span className="text-neutral-400 italic">
                                    {t('profile.information.notUpdatedYet')}
                                </span>
                            )}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </Spin>

            <UpdateProfile open={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </div>
    );
};

export default withAITracking(reactPlugin, Profile);
