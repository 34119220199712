import { PAGE_URLS, ROLES } from 'common/constants';
import { Layout } from 'common/layouts';
import {
    Daily,
    DashBoard,
    FisheryBoat,
    FisheryBoatDetail,
    Forbidden,
    Mortality,
    NotFound,
    Profile,
    SessionExpired,
    Unauthorized,
    User
} from 'pages';

const routes = [
    {
        path: PAGE_URLS.FORBIDDEN,
        name: 'Forbidden',
        element: <Forbidden />
    },
    {
        path: PAGE_URLS.UNAUTHORIZED,
        name: 'Unauthorized',
        element: <Unauthorized />
    },
    {
        path: PAGE_URLS.SESSION_EXPIRED,
        name: 'Session Expired',
        element: <SessionExpired />
    },
    {
        path: '/',
        element: <Layout />,
        isPrivate: true,
        role: ROLES.USER,
        children: [
            {
                path: PAGE_URLS.DASHBOARD,
                name: 'Dashboard',
                element: <DashBoard />,
                isPrivate: true,
                role: ROLES.USER
            },
            {
                path: PAGE_URLS.MORTALITY,
                name: 'Mortality',
                element: <Mortality />,
                isPrivate: true,
                role: ROLES.USER
            },
            {
                path: PAGE_URLS.PROFILE,
                name: 'Profile',
                element: <Profile />,
                isPrivate: true,
                role: ROLES.USER
            },
            {
                path: `${PAGE_URLS.BOAT_DETAIL}/:fisheryBoatId`,
                name: 'Boat detail',
                element: <FisheryBoatDetail />,
                isPrivate: true,
                role: ROLES.USER
            },
            {
                path: PAGE_URLS.FISHERY_BOAT,
                name: 'Fishery boat',
                element: <FisheryBoat />,
                isPrivate: true,
                role: ROLES.CAPTAIN
            },
            {
                path: PAGE_URLS.DAILY,
                name: 'Daily',
                element: <Daily />,
                isPrivate: true,
                role: ROLES.USER
            },
            {
                path: PAGE_URLS.USER,
                name: 'User',
                element: <User />,
                isPrivate: true,
                role: ROLES.ADMINISTRATOR
            }
        ]
    },
    {
        path: '*',
        element: <NotFound />
    }
];

export default routes;
