import { Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './BoatList.scss';

const BoatList = () => {
    const { t } = useTranslation();

    const { data: boatList, loading } = useSelector((s) => s.fisheryBoatList);

    return (
        <div className={`boat-list mt-5`}>
            <h2 className="mb-2">{t('boatList.title')}</h2>

            <Card bordered loading={loading} className="item-list">
                <Row gutter={[8, 8]}>
                    {boatList.map((item) => {
                        return (
                            <Col
                                xs={12}
                                sm={8}
                                md={8}
                                lg={6}
                                xl={4}
                                xxl={4}
                                className="item text-center"
                                key={item.id}
                            >
                                <Link
                                    to={`${item.hasData ? `boat-detail/${item.id}` : ''}`}
                                    key={item?.id}
                                >
                                    <Card
                                        className={`item flex justify-center items-center font-medium text-base rounded h-28 text-white relative 
                                        ${
                                            item.hasData
                                                ? 'hover:bg-slate-700 cursor-pointer bg-slate-600'
                                                : 'card-disable bg-slate-300'
                                        }
                                    `}
                                    >
                                        <div>
                                            <span>{item.name}</span>
                                            {!item.hasData && (
                                                <span
                                                    href="#"
                                                    className="btn-contact transition-all"
                                                >
                                                    {t('contactUs')}
                                                </span>
                                            )}
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                        );
                    })}
                </Row>
            </Card>
        </div>
    );
};

export default BoatList;
