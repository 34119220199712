import { Button, Form, Input, Modal, Spin } from 'antd';
import { ALERT_MESSAGES, CLASS_NAMES, TextArea } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { createFisheryBoat, getFisheryBoatListOnNavbar, updateFisheryBoat } from 'redux/thunks';

const FisheryBoatForm = ({ open = false, boat, onClose = () => {}, onReload = () => {} }) => {
    const [form] = Form.useForm();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const upsertLoading = useSelector((s) => s.fisheryBoat.loading || false);

    const isUpdateForm = !!boat;

    useEffect(() => {
        if (isUpdateForm) {
            form.setFieldsValue(boat);
        } else {
            form.resetFields();
        }
    }, [boat]);

    const createOrUpdateBoat = async (req) => {
        if (isUpdateForm) {
            handleBoatUpdate(req);
        } else {
            handleBoatCreate(req);
        }
    };

    const handleBoatCreate = async (req) => {
        try {
            await dispatch(createFisheryBoat(req)).unwrap();

            alertSuccessMessage(ALERT_MESSAGES.SUCCESSFULLY_CREATION);
            dispatch(getFisheryBoatListOnNavbar());
            form.resetFields();
            onClose();
            onReload(false);
        } catch (err) {
            alertErrorMessage(err);
        }
    };

    const handleBoatUpdate = async (req) => {
        try {
            await dispatch(updateFisheryBoat({ id: boat.id, item: req })).unwrap();

            alertSuccessMessage(ALERT_MESSAGES.SUCCESSFULLY_UPDATE);
            dispatch(getFisheryBoatListOnNavbar());
            onClose();
            onReload(false);
        } catch (err) {
            alertErrorMessage(err);
        }
    };

    const handleFormCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={
                isUpdateForm
                    ? t('fisheryBoat.form.editFisheryBoat')
                    : t('fisheryBoat.form.createFisheryBoat')
            }
            open={open}
            onCancel={handleFormCancel}
            width={580}
            footer={
                <>
                    <Button
                        type="default"
                        onClick={handleFormCancel}
                        className={CLASS_NAMES.WHITE_BUTTON}
                    >
                        {t('button.cancel')}
                    </Button>
                    <Button onClick={form.submit} className={CLASS_NAMES.BLUE_BUTTON}>
                        {isUpdateForm ? t('button.saveChange') : t('button.create')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading}>
                <Form
                    name="basic"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={createOrUpdateBoat}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item label="id" name="id" hidden={true} required={false}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="name"
                        label={t('fisheryBoat.form.name.title')}
                        rules={[
                            {
                                required: true,
                                message: t('fisheryBoat.form.name.required')
                            }
                        ]}
                    >
                        <Input placeholder={t('fisheryBoat.form.name.placeholder')} />
                    </Form.Item>

                    <Form.Item name="description" label={t('fisheryBoat.form.description.title')}>
                        <TextArea placeholder={t('fisheryBoat.form.description.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        name="externalBoatId"
                        label={t('fisheryBoat.form.externalBoatId.title')}
                        rules={[
                            {
                                required: true,
                                message: t('fisheryBoat.form.externalBoatId.required')
                            }
                        ]}
                    >
                        <Input
                            disabled={isUpdateForm}
                            placeholder={t('fisheryBoat.form.externalBoatId.placeholder')}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

FisheryBoatForm.propTypes = {
    open: PropTypes.bool,
    boat: PropTypes.object,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default FisheryBoatForm;
