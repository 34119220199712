export const COUNT_RANGES_SELECTION = [
    {
        id: '1',
        text: 'reportTable.catchCount.h500',
        from: 501,
        to: null
    },
    {
        id: '2',
        text: 'reportTable.catchCount.f201t500',
        from: 201,
        to: 500
    },
    {
        id: '3',
        text: 'reportTable.catchCount.f101t200',
        from: 101,
        to: 200
    },
    {
        id: '4',
        text: 'reportTable.catchCount.f50t100',
        from: 50,
        to: 100
    },
    {
        id: '5',
        text: 'reportTable.catchCount.l50',
        from: 0,
        to: 49
    }
];
