import { Col, Row } from 'antd';
import { FBMultipleSelection, FBRangeDate } from 'common/components';
import { CLASS_NAMES, FISH_TYPES, FORMAT_TYPES } from 'common/constants';
import { DateTimeUtils } from 'common/utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getFisheryBoatsSelection, updateFilters } from 'redux/thunks';
import './FilterBar.scss';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const initialFilters = useSelector((s) => s.filters.data);
    const fisheryBoatsSelection = useSelector((s) => s.fisheryBoatsSelection.data);

    const [filters, setFilters] = useState({
        fishNames: initialFilters.fishNames,
        fisheryBoatIds: initialFilters.fisheryBoatIds,
        fromDate: initialFilters.fromDate,
        toDate: initialFilters.toDate
    });

    useEffect(() => {
        dispatch(getFisheryBoatsSelection());
    }, []);

    const handleBoatsChange = (fisheryBoatIds) => {
        setFilters({
            ...filters,
            fisheryBoatIds: fisheryBoatIds
        });
    };

    const handleFishesChange = (fishNames) => {
        setFilters({
            ...filters,
            fishNames: fishNames
        });
    };

    const handleRangeDateChange = (values) => {
        setFilters({
            ...filters,
            fromDate: values ? values[0].format(FORMAT_TYPES.API_DATE) : null,
            toDate: values ? values[1].format(FORMAT_TYPES.API_DATE) : null
        });
    };

    const handleFilterSubmit = () => {
        dispatch(
            updateFilters({
                ...initialFilters,
                fishNames: filters.fishNames,
                fisheryBoatIds: filters.fisheryBoatIds,
                fromDate: filters.fromDate,
                toDate: filters.toDate
            })
        );
    };

    return (
        <div className="mortality-filter-bar">
            <h2 className="mb-2">{t('filterBar.title')}</h2>
            <Row className="md:text-base filter">
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('filterBar.fisheryBoat.title')}</div>
                    <FBMultipleSelection
                        placeholder={t('filterBar.fisheryBoat.placeholder')}
                        listSelectItem={fisheryBoatsSelection}
                        onChange={handleBoatsChange}
                        value={filters.fisheryBoatIds}
                    />
                </Col>

                <Col className="fish-types" xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('filterBar.fish.title')}</div>
                    <FBMultipleSelection
                        placeholder={t('filterBar.fish.placeholder')}
                        listSelectItem={FISH_TYPES.map((type) => ({ ...type, text: t(type.text) }))}
                        onChange={handleFishesChange}
                        value={filters.fishNames}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={5}>
                    <div className="font-semibold">{t('filterBar.rangeDate.title')}</div>
                    <FBRangeDate
                        placeholder={[
                            t('filterBar.rangeDate.startDatePlaceholder'),
                            t('filterBar.rangeDate.endDatePlaceholder')
                        ]}
                        onChange={handleRangeDateChange}
                        value={
                            filters.fromDate &&
                            filters.toDate && [
                                moment(
                                    DateTimeUtils.formatDateTime(
                                        filters.fromDate,
                                        FORMAT_TYPES.NORWEGIAN_DATE
                                    ),
                                    FORMAT_TYPES.NORWEGIAN_DATE
                                ),
                                moment(
                                    DateTimeUtils.formatDateTime(
                                        filters.toDate,
                                        FORMAT_TYPES.NORWEGIAN_DATE
                                    ),
                                    FORMAT_TYPES.NORWEGIAN_DATE
                                )
                            ]
                        }
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={3} className="flex items-end">
                    <button
                        className={`${CLASS_NAMES.BLUE_BUTTON} !m-0`}
                        onClick={handleFilterSubmit}
                    >
                        {t('filterBar.apply')}
                    </button>
                </Col>
            </Row>
        </div>
    );
};

export default FilterBar;
