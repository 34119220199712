import { EditOutlined } from '@ant-design/icons';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import { Avatar, Button, Space } from 'antd';
import Search from 'antd/es/input/Search';
import defaultAvatar from 'assets/Default_Avatar.png';
import { FBTable } from 'common/components';
import { CLASS_NAMES, Title } from 'common/constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getUsers } from 'redux/thunks';
import UserForm from './UserForm';

const User = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: users, loading } = useSelector((s) => s.user);
    const { data: boatList } = useSelector((s) => s.fisheryBoatList);

    const [searchText, setSearchText] = useState('');
    const [modalState, setModalState] = useState({ open: false, user: null });

    const columns = [
        {
            title: 'user.table.name',
            dataIndex: 'name',
            key: 'name',
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 1
            },
            sortDirections: ['ascend', 'descend'],
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            render: (_, record) => (
                <>
                    <Avatar.Group>
                        <Avatar
                            className="shape-avatar"
                            src={record.avatar || defaultAvatar}
                            size={55}
                            alt=""
                        />
                        <div className="avatar-info ml-2">
                            <Title level={5}>{record.name}</Title>
                            <p>{record.email || 'Email address'}</p>
                        </div>
                    </Avatar.Group>
                </>
            )
        },
        {
            title: 'user.table.company',
            dataIndex: 'company'
        },
        {
            title: 'user.table.phoneNumber',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber'
        },
        {
            title: 'user.table.address',
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: 'user.table.assignedFisheryBoat',
            dataIndex: 'assignedFisheryBoat',
            render: (_, record) =>
                boatList
                    .filter((boat) => record.fisheryBoatIds.includes(boat.id))
                    .map((boat) => <p key={boat.id}>{boat.name}</p>)
        },
        {
            title: 'user.table.action',
            dataIndex: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        className={CLASS_NAMES.GRAY_BUTTON}
                        onClick={() => handleShowModal(record)}
                    >
                        <EditOutlined />
                        <span>{t('user.table.assign')}</span>
                    </Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getUsers());
    }, []);

    const handleClose = () => setModalState({ open: false, user: null });

    const handleShowModal = (user) => setModalState({ open: true, user });

    return (
        <div className="user">
            <h2 className="mb-2">{t('user.title')}</h2>

            <div>
                <div className="mb-1">
                    <Search
                        placeholder={t('user.searchPlaceholder')}
                        onSearch={(searchText) => setSearchText(searchText.toLowerCase().trim())}
                        style={{ width: 200 }}
                    />
                </div>
                <FBTable
                    columns={columns}
                    data={users.filter(({ name }) =>
                        name.toLowerCase().trim().includes(searchText)
                    )}
                    loading={loading}
                    totalRecords={users.length}
                    rowKey="id"
                />
            </div>

            <UserForm open={modalState.open} user={modalState.user} onClose={handleClose} />
        </div>
    );
};

export default withAITracking(reactPlugin, User);
