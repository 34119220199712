import i18n from 'translation/i18n';

const COMMON_CONFIGS = {
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#0369a1',
    cancelButtonColor: '#64748b',
    cancelButtonText: i18n.t('button.cancel')
};

export const SWAL_CONFIGS = {
    DELETE: {
        ...COMMON_CONFIGS,
        title: i18n.t('deleteModal.title'),
        confirmButtonText: i18n.t('deleteModal.confirmText')
    },
    RESTORE: {
        ...COMMON_CONFIGS,
        title: i18n.t('restoreModal.title'),
        confirmButtonText: i18n.t('restoreModal.confirmText')
    }
};
