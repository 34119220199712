import { Column } from '@ant-design/plots';
import PropTypes from 'prop-types';

const FBColumn = ({ isStack = false, data = [] }) => {
    const config = {
        data,
        isStack: isStack,
        isGroup: !isStack,
        xField: 'time',
        yField: 'value',
        seriesField: 'type',
        smooth: true,
        legend: {
            position: 'top'
        }
    };

    return <Column {...config} />;
};

FBColumn.propTypes = {
    isStack: PropTypes.bool,
    data: PropTypes.array
};

export default FBColumn;
