import React from 'react';
import { DatePicker } from 'antd';
import './FBDate.scss';
import { FORMAT_TYPES } from 'common/constants';

const FBDate = ({ onChange, defaultValue, clearIcon = false, placeholder = '' }) => {
    const handleDateChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <DatePicker
            defaultValue={defaultValue}
            format={FORMAT_TYPES.NORWEGIAN_DATE}
            onChange={handleDateChange}
            clearIcon={clearIcon}
            placeholder={placeholder}
        />
    );
};

export default FBDate;
